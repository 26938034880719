<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="card mb-0 h-100">
		<div
			v-if="!loading"
			class="card-body p-3 h-100">
			<b-row
				v-if="hasRanksData && hasData"
				class="h-100">
				<b-col class="mb-0 col-8">
					<div
						class="h4 mb-0 font-weight-bold d-inline">
						{{ translate(`${showedRank}`) }}
						<div
							v-b-tooltip.hover.right
							:title="translate('rank_progress_info')"
							class="text-muted d-inline">
							<i class="fas fa-info-circle" />
						</div>
					</div>
					<small class="text-muted text-uppercase font-weight-bold d-block">{{ translate('rank_progress') }}</small>
				</b-col>
				<b-col class="col-4 text-right">
					<img
						:src="`${S3_PATH}/assets/ranks/${themeName}/${showedRank}_56x56.png`"
						alt="rank">
				</b-col>
				<b-col class="col-12 p-0">
					<hr class="m-1">
				</b-col>

				<!-- If showing current rank -->
				<template v-if="selectedRank.code_name === currentRankCodeName">
					<b-col
						class="col-12 text-center text-muted text-secondary"
						:class="{
							'mb-5' : ranksData.length <= 1,
						}">
						<!-- Use showedRank prop because it will return the current rank code_name -->
						<img
							:src="`${S3_PATH}/assets/ranks/${themeName}/${showedRank}_100x100.png`"
							alt="rank">
						<h4 class=" mt-2">
							{{ translate('congratulations') }}
						</h4>
						<h5>
							{{ translate('achieved_rank', { rank: translate(`${showedRank}`) }) }}
						</h5>
					</b-col>
					<!-- If last sub rank is not reached -->
					<b-col v-if="showSubRankProgress">
						<div
							class="text-muted mb-2 text-center"
							v-html="translate('unlock_elite_rank_title', { next_rank: translate(`${nextSubRankTitle}_rank`), icon: `${S3_PATH}/assets/ranks/${themeName}/${nextSubRankIndicator}.png` })" />
						<hr class="p-1">
						<!-- Sub rank requirements -->
						<b-col class="col-12 align-self-center">
							<b-row>
								<template
									v-if="(typeof nextSubRankReq.sponsored) !== 'undefined'">
									<b-col
										v-if="(typeof nextSubRankReq.sponsored.left) !== 'undefined'"
										v-b-tooltip.hover.top
										:title="translate('enrolled_left')"
										class="col-12 col-md-6 text-truncate pointer text-muted text-center">
										{{ translate('enrolled_left') }}
										<b-progress
											:max="nextSubRankReq.sponsored.left"
											class="mb-3">
											<b-progress-bar
												:value="currentRank.left_sponsored"
												class="bg-elite" />
											<span
												:class="currentRank.left_sponsored < nextSubRankReq.sponsored.left/2 ? 'text-dark' : 'text-white'"
												class="text-center w-100 pr-4"
												style="position: absolute;">
												{{ `${currentRank.left_sponsored}/${nextSubRankReq.sponsored.left}` }}
											</span>
										</b-progress>
									</b-col>
									<b-col
										v-if="(typeof nextSubRankReq.sponsored.right) !== 'undefined'"
										v-b-tooltip.hover.top
										:title="translate('enrolled_right')"
										class="col-12 col-md-6 text-truncate pointer text-muted text-center">
										{{ translate('enrolled_right') }}
										<b-progress
											:max="nextSubRankReq.sponsored.right"
											class="mb-3">
											<b-progress-bar
												:value="currentRank.right_sponsored"
												class="bg-elite" />
											<span
												:class="currentRank.right_sponsored < nextSubRankReq.sponsored.right/2 ? 'text-dark' : 'text-white'"
												class="text-center w-100 pr-4"
												style="position: absolute;">
												{{ `${currentRank.right_sponsored}/${nextSubRankReq.sponsored.right}` }}
											</span>
										</b-progress>
									</b-col>
									<b-col
										v-if="(typeof nextSubRankReq.sponsored.any) !== 'undefined'"
										v-b-tooltip.hover.top
										:title="translate('enrolled_any')"
										class="col-12 col-md-12 text-truncate pointer text-muted text-center">
										{{ translate('enrolled_any') }}
										<b-progress
											:max="nextSubRankReq.sponsored.any"
											class="mb-3">
											<b-progress-bar
												:value="getMaxValueEnrolled(currentRank)"
												class="bg-elite" />
											<span
												:class="getMaxValueEnrolled(currentRank) < nextSubRankReq.sponsored.any/2 ? 'text-dark' : 'text-white'"
												class="text-center w-100 pr-4"
												style="position: absolute;">
												{{ `${getMaxValueEnrolled(currentRank)}/${nextSubRankReq.sponsored.any}` }}
											</span>
										</b-progress>
									</b-col>
								</template>

								<template v-if="(typeof nextSubRankReq.rank_sponsored) !== 'undefined'">
									<b-col
										v-if="(typeof nextSubRankReq.rank_sponsored.left !== 'undefined')"
										v-b-tooltip.hover.top
										:title="translate('sponsored_rank_left', {rank: translate(nextSubRankReq.rank_sponsored.left.rank)} )"
										class="text-truncate pointer text-muted text-center">
										{{ translate('sponsored_rank_left', {rank: translate(nextSubRankReq.rank_sponsored.left.rank)} ) }}
										<b-progress
											:max="nextSubRankReq.rank_sponsored.left.qty"
											class="mb-3">
											<b-progress-bar
												:value="currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.left.rank].left"
												class="bg-elite" />
											<span
												:class="currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.left.rank].left < nextSubRankReq.rank_sponsored.left.qty/2 ? 'text-dark' : 'text-white'"
												class="text-center w-100 pr-4"
												style="position: absolute;">
												{{ `${currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.left.rank].left}/${nextSubRankReq.rank_sponsored.left.qty}` }}
											</span>
										</b-progress>
									</b-col>
									<b-col
										v-if="(typeof nextSubRankReq.rank_sponsored.right !== 'undefined')"
										v-b-tooltip.hover.top
										:title="translate('sponsored_rank_right', {rank: translate(nextSubRankReq.rank_sponsored.right.rank)} )"
										class="text-truncate pointer text-muted text-center">
										{{ translate('sponsored_rank_right', {rank: translate(nextSubRankReq.rank_sponsored.right.rank)} ) }}
										<b-progress
											:max="nextSubRankReq.rank_sponsored.right.qty"
											class="mb-3">
											<b-progress-bar
												:value="currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.right.rank].right"
												class="bg-elite" />
											<span
												:class="currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.right.rank].right < nextSubRankReq.rank_sponsored.right.qty/2 ? 'text-dark' : 'text-white'"
												class="text-center w-100 pr-4"
												style="position: absolute;">
												{{ `${currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.right.rank].right}/${nextSubRankReq.rank_sponsored.right.qty}` }}
											</span>
										</b-progress>
									</b-col>
									<b-col
										v-if="(typeof nextSubRankReq.rank_sponsored.any !== 'undefined')"
										v-b-tooltip.hover.top
										:title="translate('sponsored_rank_any', {rank: translate(nextSubRankReq.rank_sponsored.any.rank)} )"
										class="text-truncate pointer text-muted text-center">
										{{ translate('sponsored_rank_any', {rank: translate(nextSubRankReq.rank_sponsored.any.rank)} ) }}
										<b-progress
											:max="nextSubRankReq.rank_sponsored.any.qty"
											class="mb-3">
											<b-progress-bar
												:value="getMaxLegValue(currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.any.rank])"
												class="bg-elite" />
											<span
												:class="getMaxLegValue(currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.any.rank]) < nextSubRankReq.rank_sponsored.any.qty/2 ? 'text-dark' : 'text-white'"
												class="text-center w-100 pr-4"
												style="position: absolute;">
												{{ `${getMaxLegValue(currentRank.ranks_sponsored[nextSubRankReq.rank_sponsored.any.rank])}/${nextSubRankReq.rank_sponsored.any.qty}` }}
											</span>
										</b-progress>
									</b-col>
								</template>
							</b-row>
						</b-col>

						<b-col
							v-b-tooltip.hover.top
							:title="translate('team_bonus_cycle')"
							class="col-12 text-truncate pointer text-muted text-center">
							{{ translate('team_bonus_cycle') }}
							<b-progress
								:max="nextSubRankReq.cycles"
								class="mb-2">
								<b-progress-bar
									:value="currentRank.cycles"
									class="bg-elite" />
								<span
									:class="currentRank.cycles < nextSubRankReq.cycles/2 ? 'text-dark' : 'text-white'"
									class="text-center w-100 pr-4"
									style="position: absolute;">
									{{ `${currentRank.cycles}/${nextSubRankReq.cycles}` }}
								</span>
							</b-progress>
							<hr class="mx-1 my-2">
						</b-col>
					</b-col>
				</template>

				<template v-else>
					<b-col class="col-12">
						<b-row>
							<!-- <b-col
								v-if="nextRank && Object.keys(nextRank).length"
								class="col-12 col-md-5">
								<span class="font-weight-bold h6 d-inline text-muted">
									{{ translate('next_rank') }}:
								</span>
								<span class="text-primary">
									{{ translate(nextRank.attributes.code_name) }}
								</span>
							</b-col> -->
							<b-col class="col-12 col-md-12 text-center">
								<span class="font-weight-bold h6 d-inline text-muted">
									{{ translate('qualifying_period') }}:
								</span>
								<span class="text-primary">
									{{ `${currentWeek.startDate} - ${currentWeek.endDate}` }}
								</span>
							</b-col>
						</b-row>
					</b-col>
					<b-col class="col-12 p-0">
						<hr class="m-1">
					</b-col>
					<b-col
						v-if="ranksRequirements[selectedRank.code_name]"
						class="col-12 align-self-center">
						<b-row>
							<b-col
								v-b-tooltip.hover.top
								:title="translate('enrolled_left')"
								class="col-12 col-md-6 text-truncate pointer text-muted text-center">
								{{ translate('enrolled_left') }}
								<b-progress
									:max="selectedRankReq.sponsored.left"
									class="mb-3">
									<b-progress-bar
										:value="currentRank.left_sponsored"
										:variant="currentRank.left_sponsored < selectedRankReq.sponsored.left ? 'gray' : 'primary'" />
									<span
										:class="currentRank.left_sponsored < selectedRankReq.sponsored.left/2 ? 'text-dark' : 'text-white'"
										class="text-center w-100 pr-4"
										style="position: absolute;">
										{{ `${currentRank.left_sponsored}/${selectedRankReq.sponsored.left}` }}
									</span>
								</b-progress>
							</b-col>
							<b-col
								v-b-tooltip.hover.top
								:title="translate('enrolled_right')"
								class="col-12 col-md-6 text-truncate pointer text-muted text-center">
								{{ translate('enrolled_right') }}
								<b-progress
									:max="selectedRankReq.sponsored.right"
									class="mb-3">
									<b-progress-bar
										:value="currentRank.right_sponsored"
										:variant="currentRank.right_sponsored < selectedRankReq.sponsored.right ? 'gray' : 'primary'" />
									<span
										:class="currentRank.right_sponsored < selectedRankReq.sponsored.right/2 ? 'text-dark' : 'text-white'"
										class="text-center w-100 pr-4"
										style="position: absolute;">
										{{ `${currentRank.right_sponsored}/${selectedRankReq.sponsored.right}` }}
									</span>
								</b-progress>
							</b-col>

							<b-col
								v-if="selectedRankReq.rank_sponsored.left > 0"
								v-b-tooltip.hover.top
								:title="translate('sponsored_rank_left', {rank: translate(selectedRankReq.rank_sponsored.rank)} )"
								class="col-12 col-md-6 text-truncate pointer text-muted text-center">
								{{ translate('sponsored_rank_left', {rank: translate(selectedRankReq.rank_sponsored.rank)} ) }}
								<b-progress
									:max="selectedRankReq.rank_sponsored.left"
									class="mb-3">
									<b-progress-bar
										:value="currentRank.ranks_sponsored[selectedRankReq.rank_sponsored.rank].left"
										:variant="currentRank.ranks_sponsored[selectedRankReq.rank_sponsored.rank].left < selectedRankReq.rank_sponsored.left ? 'gray' : 'primary'" />
									<span
										:class="currentRank.ranks_sponsored[selectedRankReq.rank_sponsored.rank].left < selectedRankReq.rank_sponsored.left/2 ? 'text-dark' : 'text-white'"
										class="text-center w-100 pr-4"
										style="position: absolute;">
										{{ `${currentRank.ranks_sponsored[selectedRankReq.rank_sponsored.rank].left}/${selectedRankReq.rank_sponsored.left}` }}
									</span>
								</b-progress>
							</b-col>
							<b-col
								v-if="selectedRankReq.rank_sponsored.right > 0"
								v-b-tooltip.hover.top
								:title="translate('sponsored_rank_right', {rank: translate(selectedRankReq.rank_sponsored.rank)} )"
								class="col-12 col-md-6 text-truncate pointer text-muted text-center">
								{{ translate('sponsored_rank_right', {rank: translate(selectedRankReq.rank_sponsored.rank)} ) }}
								<b-progress
									:max="selectedRankReq.rank_sponsored.right"
									class="mb-3">
									<b-progress-bar
										:value="currentRank.ranks_sponsored[selectedRankReq.rank_sponsored.rank].right"
										:variant="currentRank.ranks_sponsored[selectedRankReq.rank_sponsored.rank].right < selectedRankReq.rank_sponsored.right ? 'gray' : 'primary'" />
									<span
										:class="currentRank.ranks_sponsored[selectedRankReq.rank_sponsored.rank].right < selectedRankReq.rank_sponsored.right/2 ? 'text-dark' : 'text-white'"
										class="text-center w-100 pr-4"
										style="position: absolute;">
										{{ `${currentRank.ranks_sponsored[selectedRankReq.rank_sponsored.rank].right}/${selectedRankReq.rank_sponsored.right}` }}
									</span>
								</b-progress>
							</b-col>

							<b-col
								v-b-tooltip.hover.top
								:title="translate('team_bonus_cycle')"
								class="col-12 text-truncate pointer text-muted text-center">
								{{ translate('team_bonus_cycle') }}
								<b-progress
									:max="selectedRankReq.cycles"
									class="mb-3">
									<b-progress-bar
										:value="currentRank.cycles"
										:variant="currentRank.cycles < selectedRankReq.cycles ? 'gray' : 'primary'" />
									<span
										:class="currentRank.cycles < selectedRankReq.cycles/2 ? 'text-dark' : 'text-white'"
										class="text-center w-100 pr-4"
										style="position: absolute;">
										{{ `${currentRank.cycles}/${selectedRankReq.cycles}` }}
									</span>
								</b-progress>
							</b-col>
						</b-row>
						<hr class="m-1">
					</b-col>
				</template>

				<b-col
					v-if="ranksData.length > 1"
					class="col-12 my-2 align-self-end">
					<b-row class="align-items-center justify-content-center px-1">
						<b-col

							class="d-none d-sm-block col-4 text-left px-0 pointer text-muted"
							@click="changeIndex(-1)">
							<template v-if="selectedRankIndex != 0">
								<i class="icon-lg icon-arrow-left" />
							</template>
						</b-col>
						<b-col class="col-12 col-md">
							<item-slided
								:total="ranksData.length"
								:current="selectedRankIndex"
								class="text-muted"
								@selectItem="selectItem" />
						</b-col>
						<b-col
							class="d-none d-sm-block col-4 text-right px-0 pointer text-muted"
							@click="changeIndex(1)">
							<template v-if="selectedRankIndex != ranksData.length - 1">
								<i class="icon-lg icon-arrow-right" />
							</template>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>
		<is-loading
			v-else
			class="d-flex justify-content-center align-items-center h-100"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading" />
	</div>
</template>

<script>
import { MMMD_FORMAT } from '@/settings/Dates';
import { RANK_PROGRESS } from '@/settings/RankProgress';
import { Dashboard, Ranks } from '@/translations';
import GENERAL_INFO from '@/util/GeneralInformation';
import RANK_PROGRESS_DATA from '@/util/RankProgress';
import ItemSlided from '@/components/ItemSlided';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'RankProgressWidget',
	messages: [Dashboard, Ranks],
	components: { ItemSlided },
	props: {
		userId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			themeName: process.env.VUE_APP_THEME,
			S3_PATH,
			dateFormat: MMMD_FORMAT,
			ranks: new GENERAL_INFO(),
			rankProgressData: new RANK_PROGRESS_DATA(),
			ranksRequirements: RANK_PROGRESS,
			selectedRankIndex: 0,
			removedPrevious: false,
		};
	},
	computed: {
		ranksData() {
			try {
				const { data } = this.ranks.data.response.data;
				const ranksNoAssociate = data.filter((item) => item.attributes.code_name !== 'associate');
				return ranksNoAssociate;
			} catch (error) {
				return [];
			}
		},
		hasRanksData() {
			const response = this.ranksData.length;
			return !!response;
		},
		hasData() {
			const response = this.currentRank.code_name;
			return !!response;
		},
		loading() {
			return this.rankProgressData.loading || this.ranks.loading || !this.removedPrevious;
		},
		// Rank showed in the widget
		selectedRank() {
			try {
				const data = this.ranksData[this.selectedRankIndex].attributes;
				return data;
			} catch (error) {
				return {};
			}
		},
		currentRank() {
			try {
				return this.rankProgressData.data.response.data.response;
			} catch (error) {
				return {};
			}
		},
		currentSubRank() {
			try {
				return this.currentRank.elite_rank;
			} catch (error) {
				return {};
			}
		},
		currentWeek() {
			try {
				const startDate = this.$moment(this.currentRank.date_range.start_date.date).format(this.dateFormat);
				const endDate = this.$moment(this.currentRank.date_range.end_date.date).format(this.dateFormat);
				return { startDate, endDate };
			} catch (error) {
				return {};
			}
		},
		currentRankCodeName() {
			try {
				return this.currentRank.code_name;
			} catch (error) {
				return [];
			}
		},
		selectedRankReq() {
			try {
				return this.ranksRequirements[this.selectedRank.code_name];
			} catch (error) {
				return this.ranksRequirements[this.ranksData[0]];
			}
		},
		nextSubRankCode() {
			try {
				const rankReq = this.ranksRequirements[this.currentRank.code_name];
				const subRankCodes = Object.keys(rankReq.sub_ranks);
				let nextRankIndex = 0;
				if (this.currentRank.sub_rank_of_current_rank) {
					nextRankIndex = subRankCodes.indexOf(this.currentSubRank.code_name) + 1;
				}
				const codeName = subRankCodes[nextRankIndex];

				return codeName || null;
			} catch (error) {
				return null;
			}
		},
		nextSubRankReq() {
			try {
				const rankReq = this.ranksRequirements[this.currentRank.code_name];
				if (this.nextSubRankCode !== null) {
					return rankReq.sub_ranks[this.nextSubRankCode];
				}
				return {};
			} catch (error) {
				return {};
			}
		},
		nextSubRankTitle() {
			if (this.nextSubRankCode !== null) {
				const separation = this.nextSubRankCode.split('_');
				return separation[separation.length - 1];
			}
			return '';
		},
		nextSubRankIndicator() {
			return `${this.nextSubRankTitle}_indicator`;
		},
		nextRank() {
			try {
				const data = this.ranksData[this.selectedRankIndex + 1];
				return data;
			} catch (error) {
				return {};
			}
		},
		isSubRankOfRank() {
			return this.selectedRank.code_name === this.currentRankCodeName && this.currentRank.sub_rank_of_current_rank;
		},
		showedRank() {
			return this.isSubRankOfRank ? this.currentRank.elite_rank.code_name : this.selectedRank.code_name;
		},
		showSubRankProgress() {
			return this.nextSubRankCode !== null;
		},
	},
	watch: {
		currentRank(newValue) {
			if (!newValue || this.rankProgressData.loading || this.ranks.loading) return;
			this.removedPrevious = false;
			let index = this.ranksData.map((rank) => rank.attributes.code_name).indexOf(newValue.code_name); // calculate index and remove previous ranks
			try {
				this.ranks.data.response.data.data = this.ranks.data.response.data.data.filter((rank) => this.ranksData.indexOf(rank) >= index);
			} catch (error) {
				setTimeout(() => {
					this.ranks.data.response.data.data = this.ranks.data.response.data.data.filter((rank) => this.ranksData.indexOf(rank) >= index);
				}, 1000);
			}

			index = this.ranksData.map((rank) => rank.attributes.code_name).indexOf(newValue.code_name); // re calculate index without the unneeded ranks

			this.selectedRankIndex = index > -1 ? index : 0;

			this.removedPrevious = true;
		},
	},
	mounted() {
		let { userId } = this;
		if (!this.userId) {
			const { distributorId } = this.$route.params;
			if (typeof distributorId !== 'undefined') {
				userId = distributorId;
			} else {
				userId = this.$user.details().id;
			}
		}

		this.ranks.getRanks();
		this.rankProgressData.getRankProgress(userId);
	},
	methods: {
		changeIndex(value) {
			let upcomingIndex = this.selectedRankIndex;
			upcomingIndex += value;
			if (upcomingIndex > this.ranksData.length - 1) this.selectedRankIndex = 0;
			else if (upcomingIndex < 0) this.selectedRankIndex = this.ranksData.length - 1;
			else this.selectedRankIndex += value;
		},
		selectItem(item) {
			const index = Number(item);
			if (!Number.isNaN(index)) {
				this.selectedRankIndex = index;
			}
		},
		getMaxLegValue(item) {
			return Math.max(item.left, item.right);
		},
		getMaxValueEnrolled(item) {
			return Math.max(item.right_sponsored, item.right_sponsored);
		},
	},
};
</script>
<style>
	.bg-elite {
		background-color: #59b2e7;
	}
</style>
