export const RANK_PROGRESS = {
	associate: {
		cycles: 0,
		rank_sponsored: {
			left: 0,
			right: 0,
			rank: '',
		},
		sponsored: {
			left: 0,
			right: 0,
			rank: '',
		},
	},
	star: {
		cycles: 10,
		rank_sponsored: {
			left: 0,
			right: 0,
			rank: '',
		},
		sponsored: {
			left: 1,
			right: 1,
			rank: 'associate',
		},
		sub_ranks: {
			star_elite: {
				cycles: 15,
			},
			star_premier: {
				cycles: 15,
				sponsored: {
					any: 2,
					rank: 'associate',
				},
			},
		},
	},
	all_star: {
		cycles: 25,
		rank_sponsored: {
			left: 0,
			right: 0,
			rank: '',
		},
		sponsored: {
			left: 2,
			right: 2,
			rank: 'associate',
		},
		sub_ranks: {
			all_star_select: {
				cycles: 30,
			},
			all_star_elite: {
				cycles: 35,
			},
			all_star_exclusive: {
				cycles: 35,
				rank_sponsored: {
					any: {
						qty: 1,
						rank: 'star',
					},
				},
			},
			all_star_premier: {
				cycles: 35,
				rank_sponsored: {
					left: {
						qty: 1,
						rank: 'star',
					},
					right: {
						qty: 1,
						rank: 'star',
					},
				},
			},
		},
	},
	rock_star: {
		cycles: 50,
		rank_sponsored: {
			left: 1,
			right: 1,
			rank: 'all_star',
		},
		sponsored: {
			left: 2,
			right: 2,
			rank: 'associate',
		},
		sub_ranks: {
			rock_star_select: {
				cycles: 75,
			},
			rock_star_elite: {
				cycles: 100,
			},
			rock_star_exclusive: {
				cycles: 100,
				rank_sponsored: {
					left: {
						qty: 2,
						rank: 'all_star',
					},
					right: {
						qty: 2,
						rank: 'all_star',
					},
				},
			},
			rock_star_premier: {
				cycles: 100,
				rank_sponsored: {
					left: {
						qty: 3,
						rank: 'all_star',
					},
					right: {
						qty: 3,
						rank: 'all_star',
					},
				},
			},
		},
	},
	super_star: {
		cycles: 150,
		rank_sponsored: {
			left: 4,
			right: 4,
			rank: 'all_star',
		},
		sponsored: {
			left: 2,
			right: 2,
			rank: 'associate',
		},
		sub_ranks: {
			super_star_select: {
				cycles: 200,
			},
			super_star_elite: {
				cycles: 300,
			},
			super_star_exclusive: {
				cycles: 300,
				rank_sponsored: {
					any: {
						qty: 1,
						rank: 'rock_star',
					},
				},
			},
			super_star_premier: {
				cycles: 300,
				rank_sponsored: {
					left: {
						qty: 1,
						rank: 'rock_star',
					},
					right: {
						qty: 1,
						rank: 'rock_star',
					},
				},
			},
		},
	},
	v_star: {
		cycles: 500,
		rank_sponsored: {
			left: 4,
			right: 4,
			rank: 'rock_star',
		},
		sponsored: {
			left: 2,
			right: 2,
			rank: 'associate',
		},
	},
};

export default {};
